<template>
  <PageLayout :headerText="headerText">
    <template #header-addon-left>
      <router-link :to="prevRoute" class="flex items-center p-0.5 mr-2">
        <div class="h-5 w-5 text-gray-500">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="3" d="M15 19l-7-7 7-7" />
          </svg>
        </div>
      </router-link>
    </template>
    <template #main>
      <div v-if="employeeId" class="flex flex-1 flex-col">
        <EmployeeDetails :employeeId="employeeId">
          <router-view></router-view>
        </EmployeeDetails>
      </div>
    </template>
  </PageLayout>
</template>

<script lang="ts">
import { defineComponent, computed } from "vue";
import { useRoute } from "vue-router";
import PageLayout from "@/components/PageLayout.vue";
import EmployeeDetails from "@/features/employees/details/EmployeeDetails.vue";
// store
import useEmployeeStore from "@/store/useEmployeeStore";
// other
import { repairUuid } from "@/utils/globalHelpers";

export default defineComponent({
  name: "EmployeeDetailsPage",
  components: {
    PageLayout,
    EmployeeDetails
  },
  setup() {
    // employee id from link
    const {
      params: { employeeId: employeeUrlId }
    } = useRoute();
    const employeeId = computed(() => repairUuid(employeeUrlId));

    // get employee data
    const { employee, fullName } = useEmployeeStore({ employeeId: employeeId.value });

    // get previous route, that was not employee details related
    const prevRoute = computed(() => {
      if (window.history.state.back === null) {
        return "/employees";
      } else return window.history.state.back;
    });

    const headerText = computed(() => fullName(employee.value));

    return {
      headerText,
      employeeId,
      prevRoute
    };
  }
});
</script>
