<template>
  <DetailsPage>
    <!-- Side navigation -->
    <template #sideNavigation>
      <DetailsLink
        :name="'Profile'"
        :routeNameList="['employeeProfile']"
        :link="`/employees/${cleanUuid(employeeId)}/profile`"
      >
        <template #icon>
          <!-- Heroicon name: user-circle -->
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
        </template>
      </DetailsLink>
      <DetailsLink
        :name="'Absences'"
        :routeNameList="['employeeAbsences']"
        :link="`/employees/${cleanUuid(employeeId)}/absences`"
      >
        <template #icon>
          <div class="transform rotate-45">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8"
              />
            </svg>
          </div>
        </template>
      </DetailsLink>

      <DetailsLink
        :name="'Work Areas'"
        :routeNameList="['employeeWorkAreas']"
        :link="`/employees/${cleanUuid(employeeId)}/work-areas`"
      >
        <template #icon>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01"
            />
          </svg>
        </template>
      </DetailsLink>
    </template>

    <template #sections>
      <slot></slot>
    </template>
    <template #side-list>
      <DetailsSideList :header="'Employees'" :caption="'Search for employee'">
        <template #items>
          <EmployeeDetailsListItem />
          <EmployeeDetailsListItem />
          <EmployeeDetailsListItem />
          <EmployeeDetailsListItem />
          <EmployeeDetailsListItem />
          <EmployeeDetailsListItem />
          <EmployeeDetailsListItem />
        </template>
      </DetailsSideList>
    </template>
  </DetailsPage>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import DetailsPage from "@/components/details/DetailsPage.vue";
import DetailsLink from "@/components/details/DetailsLink.vue";
import DetailsSideList from "@/components/details/DetailsSideList.vue";
import EmployeeDetailsListItem from "./EmployeeDetailsListItem.vue";
// other
import { cleanUuid } from "@/utils/globalHelpers";

export default defineComponent({
  name: "EmployeeDetails",
  components: {
    DetailsPage,
    DetailsLink,
    DetailsSideList,
    EmployeeDetailsListItem
  },
  props: {
    employeeId: {
      type: String,
      required: true
    }
  },
  setup() {
    return { cleanUuid };
  }
});
</script>
