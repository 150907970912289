<template>
  <div>
    <div
      class="relative px-6 py-5 flex items-center space-x-3 hover:bg-gray-50 focus-within:ring-2 focus-within:ring-inset focus-within:ring-blue-500"
    >
      <EmployeeAvatar :text="'JS'" :color="getRandomColor()" :size="'md'" />
      <div class="flex-1 min-w-0">
        <a class="focus:outline-none">
          <!-- Extend touch target to entire panel -->
          <span class="absolute inset-0" aria-hidden="true"></span>
          <p class="text-sm font-medium text-gray-900">
            Emma Young
          </p>
          <p class="text-sm text-gray-500 truncate">Some caption</p>
        </a>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import EmployeeAvatar from "../EmployeeAvatar.vue";
import { getRandomColor } from "@/utils/colorHelpers";

export default defineComponent({
  name: "DetailsSideListItem",
  components: { EmployeeAvatar },
  setup() {
    return { getRandomColor };
  }
});
</script>
