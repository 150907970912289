
import { defineComponent, computed } from "vue";
import { useRoute } from "vue-router";
import PageLayout from "@/components/PageLayout.vue";
import EmployeeDetails from "@/features/employees/details/EmployeeDetails.vue";
// store
import useEmployeeStore from "@/store/useEmployeeStore";
// other
import { repairUuid } from "@/utils/globalHelpers";

export default defineComponent({
  name: "EmployeeDetailsPage",
  components: {
    PageLayout,
    EmployeeDetails
  },
  setup() {
    // employee id from link
    const {
      params: { employeeId: employeeUrlId }
    } = useRoute();
    const employeeId = computed(() => repairUuid(employeeUrlId));

    // get employee data
    const { employee, fullName } = useEmployeeStore({ employeeId: employeeId.value });

    // get previous route, that was not employee details related
    const prevRoute = computed(() => {
      if (window.history.state.back === null) {
        return "/employees";
      } else return window.history.state.back;
    });

    const headerText = computed(() => fullName(employee.value));

    return {
      headerText,
      employeeId,
      prevRoute
    };
  }
});
