
import { defineComponent } from "vue";
import EmployeeAvatar from "../EmployeeAvatar.vue";
import { getRandomColor } from "@/utils/colorHelpers";

export default defineComponent({
  name: "DetailsSideListItem",
  components: { EmployeeAvatar },
  setup() {
    return { getRandomColor };
  }
});
