
import { defineComponent } from "vue";
import DetailsPage from "@/components/details/DetailsPage.vue";
import DetailsLink from "@/components/details/DetailsLink.vue";
import DetailsSideList from "@/components/details/DetailsSideList.vue";
import EmployeeDetailsListItem from "./EmployeeDetailsListItem.vue";
// other
import { cleanUuid } from "@/utils/globalHelpers";

export default defineComponent({
  name: "EmployeeDetails",
  components: {
    DetailsPage,
    DetailsLink,
    DetailsSideList,
    EmployeeDetailsListItem
  },
  props: {
    employeeId: {
      type: String,
      required: true
    }
  },
  setup() {
    return { cleanUuid };
  }
});
